import { Injectable } from '@angular/core';
import { Article, Issue } from './article';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { tap, catchError, map, filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  public articles: Article[];
  public issues: Issue[];

  public errorMessage;

  constructor(private afs: AngularFirestore) {
    console.log('ContentService created');
  }

  getIssues(): Observable<Issue[]> {
    if (this.issues) {
      console.log('Old data for issues used');
      return of(this.issues);
    }
    return this.afs.collection<Issue>('issues')
      .valueChanges()
      .pipe(
        tap(data => this.issues = data),
        // catchError(this.handleError())
        catchError(err => {
          throw new Error('error in source. Details: ' + err);
        }),
      );

  }

  getArticlesByIssue(issId): Observable<Article[]> {
    // check if it's loaded before
    if (this.articles && this.articles.length > 0 && this.articles[0].issueId === issId) {
      console.log('Old data used');
      return of(this.articles);
    }

    console.log('Fetching new data...');
    return this.afs.collection<Article>('articles', ref => ref
        .where('issueId', '==', issId)
        .orderBy('seqNo'))
      .valueChanges()
      .pipe(
        tap(data => this.articles = data),
        // catchError(this.handleError())
        catchError(err => {
          throw new Error('error in source. Details: ' + err);
        }),
      );
      

  }

  getArticleBySeqNo(issId: string, seqNo: number) {

    // check if issue s loaded before
    if (this.articles && this.articles[0].issueId === issId) {
      console.log('Old issue data used');
      return of(this.articles)
        .subscribe(
          val => val.find(fval => fval.seqNo === seqNo),
          err => this.errorMessage = err
        );
      // return this.articles.find(art => art.seqNo === seqNo);
    }

    console.log('Fetching new issue and article data...');
    return this.afs.collection<Article>('articles', ref => ref
        .where('issueId', '==', issId)
      )
      .valueChanges()
      .pipe(
        
        tap(arts => this.articles = arts)
      )
      .subscribe(
        arts => {
          arts.find(fval => fval.seqNo === seqNo);
        },
        err => this.errorMessage = err
      );

    // console.log('new data created');
    // return of(this.articles.find(art => art.seqNo === seqNo));
  }



}
