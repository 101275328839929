import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-jump-content',
  templateUrl: './jump-content.component.html',
  styleUrls: ['./jump-content.component.scss']
})
export class JumpContentComponent implements OnInit {

  @Input() styles: {};
  @Input() jumpIssue: string;
  @Input() jumpArticle: number;

  constructor(private router: Router) { }

  ngOnInit() {
    console.log(this.jumpIssue, '  ', this.jumpArticle);
  }

  goHome() {
    console.log('goHome clicked');
  }

  gotoLink() {
    console.log('issId is: ', this.jumpIssue, 'article linkJumpRef is: ', this.jumpArticle);

    this.router.navigate(['/', this.jumpIssue, this.jumpArticle]);
  }

}
