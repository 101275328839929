import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { AlertComponent } from './alert/alert.component';
import { ContentComponent } from './content/content.component';
import { HeaderComponent } from './header/header.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { VerticalNavComponent } from './vertical-nav/vertical-nav.component';
import { ClarityModule } from '@clr/angular';
import { LoginComponent } from '../user/login/login.component';



const rootRoutes: Routes = [
  // { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  declarations: [
    AlertComponent,
    ContentComponent,
    HeaderComponent,
    NotFoundComponent,
    SkeletonComponent,
    VerticalNavComponent
  ],
  imports: [
    CommonModule,
    ClarityModule,
    RouterModule.forRoot(rootRoutes)
  ],
  exports: [
    SkeletonComponent
  ]
})
export class DesignModule { }
