import { Component, OnInit } from '@angular/core';
import { Article } from '../article';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '../content.service';

// import { trigger, animate, transition, style, group, query } from '@angular/animations';

@Component({
  selector: 'app-alt2',
  templateUrl: './alt2.component.html',
  styleUrls: ['./alt2.component.scss'],
  animations: [

  ]
})
export class Alt2Component implements OnInit {

  article: Article;
  articles: Article[];

  articleName: string;
  errorMessage: string;

  seqNo: number;
  articleId: string;

  modal = false;
  imageWidth = '100';

  // GESTURE TRIALS
  eventText: string;

  constructor(private cs: ContentService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.route.paramMap.subscribe(
      params => {
        const artSeqNo = +params.get('seqNo');
        const issId = params.get('issueId');
        // this.getArticleBySeqNo(issId, artSeqNo);
        // console.log('(alt2) artSeqNo: ', artSeqNo);
        this.cs.getArticlesByIssue(issId)
          .subscribe(
            (val: Article[]) => {
              this.articles = val;
              this.article = this.articles.find(fval => fval.seqNo === artSeqNo);
              // console.log('this.article is: ', JSON.stringify(this.article));
            },
            (error: any) => this.errorMessage = error as any
          );
      }
    );
  }

  getArticleBySeqNo(issId: string, seq: number): Article {
    this.cs.getArticlesByIssue(issId)
      .subscribe(
        (val: Article[]) => {
          this.articles = val;
          console.log('articles length: ', this.articles.length);
        }
      );
    // return this.articles.filter(fval => fval.seqNo === seq)[0];
    return this.articles.find(fval => fval.seqNo === seq);

    // console.log(this.article);
  }

  swipeHandler(evt) {
    console.log('Swipe handled!');
    const x = Math.abs(evt.deltaX) > 10 ? (evt.deltaX > 0 ? -1 : 1 ) : 0 ;
    // const y = Math.abs(evt.deltaY) > 20 ? (evt.deltaY > 0 ? 'down' : 'up') : '';

    // console.log(y);

    // const maxArticleSeqNo = this.articles.length;
    const newArticleSeqNo = (this.article.seqNo + x > this.articles.length - 1) || (this.article.seqNo + x < 0) ?
                          this.article.seqNo : this.article.seqNo + x;
    // (this.article.seqNo + x > this.articles.length) ? this.article.seqNo : this.article.seqNo + x;
    

    // **********BURADA NAVIGATE TO ARTICLE YAPMAK LAZIM********
    this.router.navigate(['/', newArticleSeqNo]);

    console.log('router navigate: ', this.router.navigate(['alt2', this.article.issueId, newArticleSeqNo]));

    // this.getArticleBySeqNo(newArticleSeqNo);

    console.log('newArticleSeqNo: ', newArticleSeqNo);
  }

}


