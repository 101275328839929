import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IssuesComponent } from './issues/issues.component';
import { PagesComponent } from './pages/pages.component';
import { Alt1Component } from './alt1/alt1.component';
import { Alt2Component } from './alt2/alt2.component';
import { RouterModule } from '@angular/router';
import { JumpContentComponent } from './jump-content/jump-content.component';
import { ModalContentComponent } from './modal-content/modal-content.component';
import { RefContentComponent } from './ref-content/ref-content.component';
import { ClarityModule } from '@clr/angular';
import { VideoContentComponent } from './video-content/video-content.component';

import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { Alt3Component } from './alt3/alt3.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    pan: { direction: Hammer.DIRECTION_ALL },
    swipe: { velocity: 0.4, threshold: 20, direction: Hammer.DIRECTION_ALL }
} as any; }


const contentRoutes = [
  { path: '', redirectTo: 'issues', pathMatch: 'full' },
  { path: 'issues', component: IssuesComponent},
  { path: 'pages/:issueId', component: PagesComponent },
  { path: 'alt1/:issueId', component: Alt1Component },
  { path: 'alt2/:issueId/:seqNo', component: Alt2Component },
  { path: 'alt3/:issueId/:seqNo', component: Alt3Component },
  // { path: ':issueId/:seqNo', component: Alt2Component },
];



@NgModule({
  declarations: [
    IssuesComponent,
    PagesComponent,
    Alt1Component,
    Alt2Component,
    JumpContentComponent,
    ModalContentComponent,
    RefContentComponent,
    VideoContentComponent,
    Alt3Component
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule.forChild(contentRoutes),
    ClarityModule
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
  }
  ]
})
export class ContentModule { }


