import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss']
})
export class ModalContentComponent implements OnInit {

  @Input() styles: {};
  @Input() modalImageUrl: string;
  @Input() modalWidth: string;

  basic: boolean;

  constructor() { }

  ngOnInit() {
  }

}
