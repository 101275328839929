import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from 'src/app/content/content.service';
import { Article, Issue } from '../../content/article';

@Component({
  selector: 'app-vertical-nav',
  templateUrl: './vertical-nav.component.html',
  styleUrls: ['./vertical-nav.component.scss']
})
export class VerticalNavComponent implements OnInit {

  LINKS = [
    {routerLink : './login', icon: 'login', name: 'Login', order: 1},
    // {routerLink : './register', icon: 'assign-user', name: 'RegisterPage', order: 1},
    // {routerLink : './user', icon: 'user', name: 'User', order: 1},
    {routerLink : './issues', icon: 'list', name: 'Issues', order: 2},
    {icon: 'logout', name: 'Logout', click: 'logout()', order: 3},
    // {routerLink : './page-standard', icon: 'book', name: 'Sample page', order: 2},
    // {routerLink : './alt1', icon: 'bug up', name: 'Alternative 1', order: 4},
    // {routerLink : './page-index', icon: 'view-list', name: 'Dizin', order: 5},
    // {routerLink : './pages', icon: 'details', name: 'Alternatif 2', order: 6},
    // {routerLink : './alt2', icon: 'bug left', name: 'Alternative 2', order: 6},
    // {routerLink : './page-index2', icon: 'view-list', name: 'İçindekiler-3', order: 7},
    // {routerLink : './alt3', icon: 'bug right', name: 'Alternative 3', order: 8},

    // {routerLink : './todos', icon: 'checkbox-list', name: 'Todo list'},
    // {routerLink : './troubles', icon: 'wrench', name: 'Problems'},
    // {routerLink : './changelog', icon: 'new', name: 'Changelog'}
  ];

  collapsed = [];

  isLoggedIn$: Observable<boolean>;
  isLoggedOut$: Observable<boolean>;

  // issueId;
  articles: Article[];
  issues: Issue[];
  errorMessage: string;


  constructor(private afAuth: AngularFireAuth,
              private route: ActivatedRoute,
              private cs: ContentService) { }

  ngOnInit() {
    this.afAuth.authState.subscribe(user => console.log(user));

    this.isLoggedIn$ = this.afAuth.authState.pipe(map(user => !!user) );
    this.isLoggedOut$ = this.isLoggedIn$.pipe(map(loggedIn => !loggedIn));

    this.cs.getIssues()
    .subscribe(
      (val: Issue[]) => this.issues = val,
      (err: any) => this.errorMessage = err as any
    );
  }

  logout() {
    this.afAuth.auth.signOut();
  }

}
