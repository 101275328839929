import { Component, OnInit } from '@angular/core';
import { Article } from '../article';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from '../content.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  articles: Article[];
  errorMessage: string;

  constructor(private cs: ContentService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(
      params => {
        const issId = params.get('issueId');
        this.cs.getArticlesByIssue(issId)
        .subscribe(
          (val: Article[]) => this.articles = val,
          (error: any) => this.errorMessage = error as any
        );
      }
    );
    // const issueId = this.route.snapshot.paramMap.get('issueId');
    // console.log('issueId: ', issueId);
    // console.log('(pages) navigated here... now fetching data');
    // this.cs.getArticlesByIssue(issueId)
    // .subscribe(
    //   (val: Article[]) => this.articles = val,
    //   (error: any) => this.errorMessage = error as any
    // );
  }

}
