import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit {

  header: boolean;

  constructor(private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    // this.route.paramMap
    //   .subscribe(
    //   params => {

    //     // if (params.get('issueId')) {
    //     //   this.header = true;
    //     // } else {
    //     //   this.header = false;
    //     // }
    //     // console.log(this.header);
    //     console.log(params.get('seqNo'));
    //   }
    // );

    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        // if (event.url === '/login')
        if (/alt2/.test(event.url)) {
          this.header = false;
        } else {
          // console.log("NU")
          this.header = true;
        }
      }
    });
  }

}
