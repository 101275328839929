import { Component, OnInit } from '@angular/core';
import { Article } from '../article';
import { ContentService } from '../content.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-alt1',
  templateUrl: './alt1.component.html',
  styleUrls: ['./alt1.component.scss']
})
export class Alt1Component implements OnInit {

  articles: Article[];
  article: Article;
  errorMessage: string;

  constructor(private cs: ContentService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
      this.route.paramMap.subscribe(
      params => {
        console.log('(alt1) params will be fetched');
        const issId = params.get('issueId');
        console.log('(alt1) params received');
        this.getArticlesByIssue(issId);
      }
    );
  }

  getArticlesByIssue(issId: string) {
    this.cs.getArticlesByIssue(issId)
      .subscribe(
        (val: Article[]) => this.articles = val,
        (error: any) => this.errorMessage = error as any
      );
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }



}
