import { Component, OnInit } from '@angular/core';
import { Issue } from '../article';
import { Router } from '@angular/router';
import { ContentService } from '../content.service';

@Component({
  selector: 'app-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.scss']
})
export class IssuesComponent implements OnInit {

  issues: Issue[];
  errorMessage: string;

  constructor(private cs: ContentService,
              private router: Router) { }

  ngOnInit() {
    this.cs.getIssues()
      .subscribe(
        (val: Issue[]) => this.issues = val,
        (err: any) => this.errorMessage = err as any
      );

  }

}
