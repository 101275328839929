import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ref-content',
  templateUrl: './ref-content.component.html',
  styleUrls: ['./ref-content.component.scss']
})
export class RefContentComponent implements OnInit {

  @Input() styles: {};
  @Input() text: string;
  @Input() placement: string;

  

  constructor() { }

  ngOnInit() { }

}
